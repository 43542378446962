import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { listPpds } from "../../actions/member/ppds_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import HeaderPPDS from "./HeaderPPDS";
import { StyleSheet, css } from "aphrodite";
import {
  getListPpds,
  getPpdsError,
  getPpdsPending,
} from "../../reducers/member/ppds_reducer";
import moment from 'moment';

const styles = StyleSheet.create({
  paddingLeft: 4,
  paddingRight: 4,
  width: 30
})

class ListPPDS extends Component {
  // componentDidMount() {
  //   const { listPpds } = this.props;
  //   let param = {
  //     page: 1,
  //     length: 10,
  //     search_text: "",
  //     id: "",
  //   };
  //   listPpds(param);
  // }
  // shouldComponentRender() {
  //   const { registrationList_pending } = this.props;
  //   if (registrationList_pending === false) {
  //     return false;
  //   }
  //   return true;
  // }
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search_text: "",
      exam_year: '',
      status_graduated: null,
      university_to: "",
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    this.setState(state);
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  componentDidMount() {
  }

  detailRecord(id) {
    // const { match } = this.props;
    // this.props.history.push(match.url + "/detail/" + id);
    this.props.history.push(`/ppds/detail/${id}/umum`);
  }

  tableRef = React.createRef();
  render() {
    let data = [];
    let records_total = 0;
    const { listPpds, listPpds_data } = this.props;
    if (listPpds_data) {
      data = listPpds_data.data;
      records_total = listPpds_data.records_total;
    }

    return (
      <ContentContainer>
        <HeaderPPDS handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 10,
              headerStyle: {
                paddingLeft: 4,
              },
              cellStyle: {
                paddingLeft: 4,
                textAlign: "center",
              },
            },
            {
              title: "Nama",
              field: "name",
              cellStyle: { styles }
            },
            {
              title: "NPA IDI/NIK",
              field: "npa_idi",
              headerStyle: { styles },
              cellStyle: { styles }
            },
            {
              title: "Universitas Asal",
              field: "university_before_data.name",
              headerStyle: { styles },
              cellStyle: { styles }
            },
            {
              title: "Universitas Tujuan",
              field: "university_approved_data.name",
              headerStyle: { styles },
              cellStyle: { styles }
            },
            {
              title: "Periode Ujian",
              field: "exam_date",
              render: ({ exam_date }) => {
                const date = exam_date ? moment(exam_date).format("MMMM YYYY") : '-'
                return (<>{date}</>)
              },
              headerStyle: { styles },
              cellStyle: { styles }
            },
            {
              title: "Estimasi Lulus",
              field: "exam_date",
              render: ({ exam_date }) => {
                const date = exam_date ? moment(exam_date).add(53, 'M').format("MMMM YYYY") : '-'
                return (<>{date}</>)
              },
              headerStyle: { styles },
              cellStyle: { styles }
            },
            // {
            //   title: "Status Ujian",
            //   field: "status",
            //   render: ({ status }) => {
            //     return (
            //       <TableStatusComponent
            //         status={status === 1 ? "Lulus" : "Tidak Lulus"}
            //       />
            //     );
            //   },
            // },
            {
              title: "Status Kelulusan",
              field: "status_graduated",
              render: ({ status_graduated }) => {
                return (
                  <TableStatusComponent
                    status={status_graduated === 1 ? "Lulus" : status_graduated === 0 ? "Belum Lulus" : "Keluar"}
                  />
                );
              },
              headerStyle: { width: 70 },
              cellStyle: { width: 70, textAlign: "center", padding: 0 }
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                      Detail
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
              headerStyle: { styles },
              cellStyle: { styles }
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search_text,
                exam_year: this.state.exam_year,
                university_to: this.state.university_to,
                status_graduated: this.state.status_graduated
              };
              listPpds(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              zIndex: 0,
              // width: 10,
              // paddingLeft: "40px",
              // paddingRight: "40px",
            }, //change header padding
            // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listPpds }) {
  return {
    listPpds_data: getListPpds(listPpds),
    listPpds_error: getPpdsError(listPpds),
    listPpds_pending: getPpdsPending(listPpds),
  };
}

export default connect(mapStateToProps, { listPpds })(
  withRouter(ListPPDS)
);
