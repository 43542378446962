import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { registrationList } from "../../actions/registrasi_sp2/registrasi_action";
import {
  getRegistrationListPending,
  getRegistrationList,
  getRegistrationListError,
} from "../../reducers/registrasi_sp2/registrasi_reducer";
// import { interestList } from "../../actions/master-data/interest_action.js";
// import {
//   getInterestListPending,
//   getInterestList,
//   getInterestListError,
// } from "../../reducers/master-data/interest_reducer";

import { editPpds } from "../../actions/member/ppds_sp2_action";
import {
  getEditPpds,
  getEditPpdsPending,
  getEditPpdsError,
} from "../../reducers/member/ppds_reducer";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import InputComponent from "../../components/form/InputComponent";
import { connect } from "react-redux";

class ModalEditNpaIdi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      npa_idi: this.props.npa_idi,
    };
    this.handleChangeNpaIdi = this.handleChangeNpaIdi.bind(this);
    this.editNpaIdi = this.editNpaIdi.bind(this);
  }

  handleChangeNpaIdi(e) {
    if (e !== null) {
      this.setState({ npa_idi: e.target.value });
    } else {
      this.setState({ npa_idi: "" });
    }
  }

  editNpaIdi() {
    const { editPpds, onHide, registrationList } = this.props;
    const param_editNpaIdi = {
      id: this.props.id,
      email: this.props.email,
      name: this.props.name,
      university_before: this.props.university_before,
      graduated: this.props.graduated,
      birthday: this.props.birthday,
      place_of_birth: this.props.place_of_birth,
      address: this.props.address,
      handphone: this.props.handphone,
      ip_s1: this.props.ip_s1,
      ip_profesi: this.props.ip_profesi,
      ip_cumulation: this.props.ip_cumulation,
      working_hospital_1: this.props.working_hospital_1,
      working_hospital_2: this.props.working_hospital_2,
      working_hospital_3: this.props.working_hospital_3,
      destination_universities: [
        {
          university_id: this.props.prodi,
          period_selection_month: this.props.bulan,
          period_selection_year: this.props.tahun,
          interest: this.props.interest,
        },
      ],
      notes: this.props.notes,
      kolegium_no: this.props.kolegium_no,
      npa_idi:
        this.state.npa_idi !== "" ? this.state.npa_idi : this.props.npa_idi,
    };
    const callback = () => {
      onHide();
      let param_registrationList = {
        id: this.props.id,
      };
      registrationList(param_registrationList);
    };
    editPpds(param_editNpaIdi, callback);
  }

  componentDidMount() {
    let param_registrationList = {
      id: this.props.id,
    };
    this.props.registrationList(param_registrationList);
  }

  render() {
    const { registrationList_data } = this.props;
    let npa_idi = "";
    if (registrationList_data) {
      let registrationData = registrationList_data.data[0];
      npa_idi = registrationData.npa_idi;
    }
    console.log("ini adalah render", registrationList_data);
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        interest={this.props.interest}
        prodi={this.props.prodi}
        bulan={this.props.bulan}
        tahun={this.props.tahun}
        email={this.props.email}
        name={this.props.name}
        university_before={this.props.university_before}
        graduated={this.props.graduated}
        birthday={this.props.birthday}
        place_of_birth={this.props.place_of_birth}
        address={this.props.address}
        handphone={this.props.handphone}
        kolegium_no={this.props.kolegium_no}
        npa_idi={this.props.npa_idi}
        working_hospital_1={this.props.working_hospital_1}
        working_hospital_2={this.props.working_hospital_2}
        working_hospital_3={this.props.working_hospital_3}
        notes={this.props.notes}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 50 }}>
            Edit NPA IDI/NIK
          </p>
        </Modal.Header>
        <Modal.Body>
          <Row className={css(styles.bodyModal)}>
            <LabelInputVerticalComponent label="NPA IDI/NIK" width="600px">
              <InputComponent
                isClearable={false}
                placeholder="NPA IDI/NIK"
                onChange={this.handleChangeNpaIdi}
                defaultValue={npa_idi}
                id="npa_idi"
              />
            </LabelInputVerticalComponent>
          </Row>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              startIcon={<CheckIcon />}
              onClick={() => this.editNpaIdi()}
            >
              SIMPAN
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function mapStateToProps({
  registrationList,
  // interestList,
  editPpds,
}) {
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
    // interestList_pending: getInterestListPending(interestList),
    // interestList_data: getInterestList(interestList),
    // interestList_error: getInterestListError(interestList),
    editPpds_pending: getEditPpdsPending(editPpds),
    editPpds_data: getEditPpds(editPpds),
    editPpds_error: getEditPpdsError(editPpds),
  };
}

export default connect(mapStateToProps, {
  registrationList,
  // interestList,
  editPpds,
})(ModalEditNpaIdi);
