import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import { formValueSelector } from "redux-form"; // ES6

import { detailMember, editMember } from "../../actions/member/member_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getMemberPending,
  getDetailMember,
  getMemberError,
} from "../../reducers/member/member_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { universityList } from "../../actions/master-data/university_action";
import {
  getUniversityListPending,
  getUniversityList,
} from "../../reducers/master-data/university_reducer";

import {
  renderInput,
  renderSelect,
  renderInputSosmed,
  renderDate,
} from "../../components/form/FormComponent";
import { getCityList } from "../../reducers/master-data/city_reducer";
import { getUser } from "../../helpers/user";
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  listSubspesialis
} from "../../actions/master-data/subspesialis_action";
import {
  getSubspesialisPending,
  getListSubspesialis,
  getSubspesialisError,
} from "../../reducers/master-data/subspesialis_reducer";

class MemberDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false, isEditFile: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUniversityChange = this.handleUniversityChange.bind(this);

  }

  componentDidMount() {
    detailMember(getUser().id);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    const { universityList } = this.props;
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    universityList({ length: 999, is_adaptation: 0 });
    this.props.reset();
  }

  handleTriggerEditFile() { }

  handleUniversityChange(value) {
    const { change } = this.props;
    if (value && value.city) {
      change("city", value.city);
    } else {
      change("city", null);
    }
  }

  handleSubmit(values) {
    const { detailMember_data, editMember, detailMember } = this.props;
    let param = {
      ...values,
      id: detailMember_data[0].id,
      kolegium_no: values.kolegium_no,
      npa_idi: values.npa_idi,
      university_before: values.university_before_data?.value,
      graduated: values.graduated?.value,
      place_of_birth: values.place_of_birth?.value,
      birthday: values.birthday,
      handphone: values.handphone,
      name: values.name,
      email: values.email,
      address: values.address,
      ip_s1: values.ip_s1,
      ip_profesi: values.ip_profesi,
      ip_cumulation: values.ip_cumulation,
      destination_universities: [{
        university_id: values.university_destination_data?.value,
        period_selection_year: values.period_selection_year?.value,
        period_selection_month: values.period_selection_month?.value
      }],
      isPPDS: true,
    };
    const callback = () => {
      detailMember(detailMember_data[0].id);
      this.handleTriggerFormEdit();
    };

    editMember(param, callback);
  }

  render() {
    const {
      listCity_data,
      editMember_pending,
      handleSubmit,
      listBranch_data,
      listUniversity_data,
      listSubspesialis_data
    } = this.props;
    const { isEdit } = this.state;
    const { formValue } = this.props;
    let currentYear = new Date().getFullYear();
    let cityOptions = [],
      yearOptions = [],
      universityOptions = [],
      subspecialistOptions = [];
    if (listUniversity_data) {
      universityOptions = listUniversity_data.data.map((item) => ({
        label: `${item.name} (${item.abbreviation})`,
        value: item.id,
        city: item.city.name,
      }));
      universityOptions.splice(0, 0, { label: "Lainnya...", value: "other" })
    }
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: `${i}` }];
    }

    if (listCity_data && listCity_data.data.length !== 0) {
      cityOptions = listCity_data.data.map((item) => ({
        label: item.name,
        value: item.name,
      }));
    }


    let monthOptions = [
      { label: "Januari", value: "1" },
      { label: "Juli", value: "7" },
    ];

    return (
      <form
        onSubmit={handleSubmit(this.handleSubmit)}
        style={{ width: "100%" }}
      >
        <Row
          vertical="center"
          horizontal="space-between"
          style={{ width: "100%", marginBottom: 20 }}
        >
          <DetailNameComponent name="" />
          <EditButtonComponent
            isEdit={isEdit}
            triggerIsEdit={this.handleTriggerFormEdit}
            isLoading={editMember_pending}
          />
        </Row>
        <DetailNameComponent name="Profile Peserta" />
        <FormContainer label="No. Collegium">
          <Field
            isDetail={!isEdit}
            name="kolegium_no"
            placeholder="Masukkan no.collegium"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="NPA IDI/NIK">
          <Field
            isDetail={!isEdit}
            name="npa_idi"
            placeholder="Masukkan NPA IDI/NIK"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="Asal FK">
          <Field
            isDetail={!isEdit}
            name="university_before_data"
            placeholder="Pilih asal FK"
            component={renderSelect}
            options={universityOptions}
          />
        </FormContainer>
        <FormContainer label="Tahun Lulus">
          <Field
            isDetail={!isEdit}
            name="graduated"
            placeholder="Pilih tahun lulus"
            component={renderSelect}
            options={yearOptions}
          />
        </FormContainer>
        <FormContainer label="Nama Lengkap Tanpa Gelar">
          <Field
            isDetail={!isEdit}
            name="name"
            placeholder="Masukkan nama lengkap tanpa gelar"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="Tempat, Tgl Lahir">
          <Row
            style={{ width: "100%" }}
            horizontal={isEdit ? "space-between" : ""}
            vertical="center"
          >
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                name="place_of_birth"
                placeholder="Masukkan Kota"
                options={cityOptions}
                component={renderSelect}
              />
            </Column>
            {!isEdit && <div className={css(styles.dot)} />}
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                name="birthday"
                component={renderDate}
              />
            </Column>
          </Row>
        </FormContainer>
        <FormContainer label="Email">
          <Field
            isDetail={!isEdit}
            name="email"
            placeholder="Masukkan email"
            component={renderInput}
          />
        </FormContainer>

        <FormContainer label="No Telepon">
          <Row
            style={{ width: "100%" }}
            horizontal={isEdit ? "space-between" : ""}
          >
            <Column style={{ width: isEdit ? "100%" : "" }}>
              <Field
                isDetail={!isEdit}
                type="number"
                name="handphone"
                placeholder="Masukkan telepon utama"
                component={renderInput}
              />
            </Column>
          </Row>
        </FormContainer>
        <FormContainer label="Alamat">
          <Field
            isDetail={!isEdit}
            name="address"
            placeholder="Masukkan alamat"
            component={renderInput}
          />
        </FormContainer>
        <br></br>
        <DetailNameComponent name="Indeks Prestasi" />
        <FormContainer label="IP S1">
          <Field
            isDetail={!isEdit}
            name="ip_s1"
            placeholder="Masukkan IP S1"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="IP Profesi">
          <Field
            isDetail={!isEdit}
            name="ip_profesi"
            placeholder="Masukkan IP Profesi"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="IP Kumulatif">
          <Field
            isDetail={!isEdit}
            name="ip_cumulation"
            placeholder="Masukkan IP Kumulatif"
            component={renderInput}
          />
        </FormContainer>
        <br></br>
        <DetailNameComponent name="Program Studi Orthopaedi dan Traumatologi yang Dituju" />
        <FormContainer label="Fakultas Kedokteran">
          <Field
            isDetail={!isEdit}
            name="university_destination_data"
            placeholder="Pilih FK tujuan"
            component={renderSelect}
            options={universityOptions}
          />
        </FormContainer>
        <br></br>
        <DetailNameComponent name="Periode Seleksi Ujian Masuk" />
        <FormContainer label="Bulan">
          <Field
            isDetail={!isEdit}
            name="period_selection_month"
            placeholder="Pilih Bulan"
            component={renderSelect}
            options={monthOptions}
          />
        </FormContainer>
        <FormContainer label="Tahun">
          <Field
            isDetail={!isEdit}
            name="period_selection_year"
            placeholder="Pilih tahun"
            component={renderSelect}
            options={yearOptions}
          />
        </FormContainer>
      </form>
    );
  }
}

const styles = StyleSheet.create({
  dot: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: "black",
    margin: "0px 10px",
  },
  icon: {
    position: "absolute",
    top: 0,
    height: 20,
    color: "#495057",
    width: 20,
    // left: 10,
  },
  detailSectionName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 20
  }
});

function validate(values) {
  const {
    place_of_birth,
    birthday,
    identity_type,
    identity_no,
    tribes,
    gender,
    religion,
    primary_phone,
    primary_email,
    home_address,
    office_address,
    contact_emergency_name,
    contact_emergency_phone,
  } = values;
  const errors = {};
  if (!place_of_birth) {
    errors.place_of_birth = "Tempat lahir wajib diisi";
  }
  if (!birthday) {
    errors.birthday = "Tanggal lahir wajib diisi";
  }
  if (!identity_type) {
    errors.identity_type = "Jenis identitas wajib diisi";
  }
  if (!identity_no) {
    errors.identity_no = "Nomor identitas wajib diisi";
  }
  if (!tribes) {
    errors.tribes = "Suku bangsa wajib diisi";
  }
  if (!gender) {
    errors.gender = "Jenis Kelamin wajib diisi";
  }
  if (!religion) {
    errors.religion = "Agama wajib diisi";
  }
  if (!primary_phone) {
    errors.primary_phone = "Telepon utama wajib diisi";
  }

  if (!primary_email) {
    errors.primary_email = "Email utama wajib diisi";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(primary_email)) {
    errors.primary_email = "format email salah";
  }

  if (!home_address) {
    errors.home_address = "Alamat Rumah wajib diisi";
  }

  if (!office_address) {
    errors.office_address = "Alamat RS tempat praktik wajib diisi";
  }
  // if (!mailing_address,,) {
  //   errors.place_of_birth = "Tempat lahir wajib diisi";
  // }
  if (!contact_emergency_name) {
    errors.contact_emergency_name = "Nama Kontak wajib diisi";
  }
  if (!contact_emergency_phone) {
    errors.contact_emergency_phone = "Nomor Kontak wajib diisi";
  }
  // if (!twitter) {
  //   errors.twitter = "Tempat lahir wajib diisi";
  // }
  // if (!linkedin) {
  //   errors.linkedin = "Tempat lahir wajib diisi";
  // }
  // if (!fb) {
  //   errors.fb = "Tempat lahir wajib diisi";
  // }
  // if (!instagram) {
  //   errors.instagram = "Tempat lahir wajib diisi";
  // }

  return errors;
}

MemberDetail = reduxForm({
  // a unique name for the form
  form: "MemberDataDiri",
  // validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(MemberDetail);

function mapStateToProps(state) {
  const { detailMember, editMember, listCity, universityList } = state;
  let initialValues = {};
  const detailMember_data = getDetailMember(detailMember);
  let monthOptions = [
    { label: "Januari", value: "1" },
    { label: "Februari", value: "2" },
    { label: "Maret", value: "3" },
    { label: "April", value: "4" },
    { label: "Mei", value: "5" },
    { label: "Juni", value: "6" },
    { label: "Juli", value: "7" },
    { label: "Agustus", value: "8" },
    { label: "September", value: "9" },
    { label: "Oktober", value: "10" },
    { label: "November", value: "11" },
    { label: "Desember", value: "12" },
  ];
  if (detailMember_data && detailMember_data.length !== 0) {
    initialValues = {
      graduated: {
        label: `${detailMember_data[0].graduated}`,
        value: detailMember_data[0].graduated,
      },
      name: detailMember_data[0].name,
      kolegium_no: detailMember_data[0].kolegium_no,
      npa_idi: detailMember_data[0].npa_idi,
      university_before_data: {
        label: `${detailMember_data[0].university_before_data.name} (${detailMember_data[0].university_before_data.abbreviation})`,
        value: detailMember_data[0].university_before_data.id,
      },
      university_destination_data: {
        label: `${detailMember_data[0].ppds_destination_university_data[0].university_data.name} (${detailMember_data[0].ppds_destination_university_data[0].university_data.abbreviation})`,
        value: detailMember_data[0].ppds_destination_university_data[0].university_data.id,
      },
      place_of_birth: {
        label: `${detailMember_data[0].place_of_birth}`,
        value: detailMember_data[0].place_of_birth,
      },
      birthday: detailMember_data[0].birthday,
      handphone: detailMember_data[0].handphone,
      address: detailMember_data[0].address,
      ip_s1: detailMember_data[0].ip_s1,
      email: detailMember_data[0].email,
      ip_profesi: detailMember_data[0].ip_profesi,
      ip_cumulation: detailMember_data[0].ip_cumulation,
      period_selection_year: {
        label: `${detailMember_data[0].ppds_destination_university_data[0].period_selection_year}`,
        value: detailMember_data[0].ppds_destination_university_data[0].period_selection_year,
      },
      period_selection_month: monthOptions[parseInt(`${detailMember_data[0].ppds_destination_university_data[0].period_selection_month}`) - 1],
    };
  }

  const selector = formValueSelector("MemberDataDiri");
  const formValue = selector(
    state,
    "secondary_email",
    "secondary_phone",
    "branch"
  );
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_error: getMemberError(detailMember),
    detailMember_pending: getMemberPending(detailMember),
    editMember_error: getMemberError(editMember),
    editMember_pending: getMemberPending(editMember),
    listCity_data: getCityList(listCity),
    listUniversity_data: getUniversityList(universityList),
    listUniversity_pending: getUniversityListPending(universityList),
    initialValues,
    formValue,
  };
}

export default connect(mapStateToProps, {
  detailMember,
  editMember,
  listSubspesialis,
  universityList
})(withRouter(MemberDetail));
