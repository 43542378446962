import React, { Component } from "react";
import { connect } from "react-redux";
import { Column, Row } from "simple-flexbox";
import { listPPDSAdmin } from "../../actions/manajemen-ppds/manajemen_ppds_action";
import { universityList } from "../../actions/master-data/university_action";
import { listCity } from "../../actions/master-data/city_action";
import {
  getListEducation,
  getEducationError,
  getEducationPending,
} from "../../reducers/member/education_reducer";
import {
  getUniversityList,
  getUniversityListPending,
} from "../../reducers/master-data/university_reducer";
import {
  getCityListPending,
  getCityList
} from "../../reducers/master-data/city_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { getUser } from "../../helpers/user";
import PPDSHeader from "./PPDSHeader";
import moment from "moment";

import SelectComponent from "../../components/form/SelectComponent";


class ListManajemenPPDS extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: null,
      search_text: "",
      showModalAdd: false,
      showModalDelete: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
    this.handleOpenModalDelete = this.handleOpenModalDelete.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/manajemen-ppds/detail/${id}`);
  }
  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  handleYearSelectChange(e) {
    if (e) {
      
    } else {
    }
  }

  componentDidMount() {
    const { universityList, listCity, match } = this.props;
    const param = {
      page: 1,
      length: 999,
    };
    universityList(param);
    listCity(param);
    if (match.params.id && this.state.id === null) {
      this.setState({ id: match.params.id })
    }
  }

  render() {
    const { listPPDSAdmin, listPPDS_data } = this.props;
    const { search_text, showModalAdd, modalDeleteShow, id } = this.state;
    let data = [];
    let records_total = 0;
    if (listPPDS_data) {
      data = listPPDS_data.data;
      records_total = listPPDS_data.records_total;
    }
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <ContentContainer>
        <PPDSHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Nama",
              field: "name",
            },
            {
              title: "NPA IDI/NIK",
              field: "npa_idi",
            },
            {
              title: "Universitas Asal",
              field: "university_before_data.name",
            },
            {
              title: "Universitas Tujuan",
              field: "university_approved_data.name",
            },
            {
              title: "Tahun Ujian",
              render: ({exam_date}) => {
                return exam_date !== null ? moment(exam_date).format("YYYY") : '-'
              },
            },
            {
              title: "Status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Gagal"
                        : status === 1
                          ? "Diterima"
                          : "Lulus"
                    }
                  />
                );
              },
            },
           
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              console.log(search_text)
              let param = {
                user_id: getUser().id,
                page: query.page + 1,
                length: 10,
                exam_year: search_text !== null ? search_text.value : '',
                id: id
              };
              listPPDSAdmin(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ universityList, listPPDSAdmin, listCity }) {
  return {
    listPPDS_data: getListEducation(listPPDSAdmin),
    listPPDS_error: getEducationError(listPPDSAdmin),
    listPPDS_pending: getEducationPending(listPPDSAdmin),
    listUniversity_data: getUniversityList(universityList),
    listUniversity_pending: getUniversityListPending(universityList),
    listCity_data: getCityList(listCity),
    listCity_pending: getCityListPending(listCity),
  };
}

export default connect(mapStateToProps, {
  listPPDSAdmin,
  universityList,
  listCity
})(ListManajemenPPDS);
